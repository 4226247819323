import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userSlice from './slices/userSlice';
import checkoutSlice from './slices/checkoutSlice';
import checkoutDetail from './slices/checkoutDetailSlice';
import permissionSlice from './slices/permissionSlice';
import tipSlice from "./slices/tipSlice";
import storage from 'redux-persist/lib/storage';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from 'redux-persist';

const checkoutPersistConfig = {
	key: 'checkout',
	storage: storage,
	whitelist: [
		'memberIdsConfig',
		'listAddressSelect',
		'listProductSalon',
		'listServiceSalon',
		'listStaffSalon',
		'listOpenServiceLockReason',
		'listPaymentOnlineCard',
		'currentSalonId',
		'listStaffWorkingOnSalon'
	],
};

const permissionPersistConfig = {
	key: 'permission',
	storage: storage,
	whitelist: ['listRoutingPermission'],
};

const rootReducer = combineReducers({
  user: userSlice,
  permission: persistReducer(permissionPersistConfig, permissionSlice),
  checkout: persistReducer(checkoutPersistConfig, checkoutSlice),
  checkoutDetail: checkoutDetail,
  tip: tipSlice,
});

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.REACT_APP_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type StoreType = typeof store;
export default store;
