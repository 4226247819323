import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { ScrollToTop, Loading } from "components";
import Page404 from "../pages/errors/404";
import Page403 from "../pages/errors/403";
import Page500 from "../pages/errors/500";
import { useAppSelector } from "redux/store";

const Checkout = React.lazy(() => import("../pages/checkout"));
const CheckoutDetail = React.lazy(() => import("../pages/checkout/detail"));
const ReturnProduct = React.lazy(() => import("../pages/return-product"));

const BillReceipts = React.lazy(() => import("../pages/list-receipts"));
const ApproveWithdrawal = React.lazy(
  () => import("../pages/approve-withdrawal")
);
const ApproveWithdrawalDetail = React.lazy(
  () => import("../pages/approve-withdrawal/detail")
);

const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess);
  if (urlAccess) {
    return urlAccess.find((i) => i.path === url) ? element : <Page403 />;
  }
};

function MainRouter() {
  const routes = useRoutes([
    {
      path: "/",
      element: AuthorizationPage(<Checkout />, "/"),
    },
    {
      path: "/checkout/old/:billIdSql",
      element: AuthorizationPage(<CheckoutDetail />, "/checkout/:{id}"),
    },
    {
      path: "/checkout/:id",
      element: AuthorizationPage(<CheckoutDetail />, "/checkout/:{id}"),
    },

    {
      path: "/tra-hang",
      element: AuthorizationPage(<ReturnProduct />, "/tra-hang"),
    },
    {
      path: "/danh-sach-hoa-don",
      element: AuthorizationPage(<BillReceipts />, "/danh-sach-hoa-don"),
    },
    {
      path: "/phe-duyet-rut-tien",
      element: AuthorizationPage(<ApproveWithdrawal />, "/phe-duyet-rut-tien"),
    },
    {
      path: "/phe-duyet-rut-tien/:id",
      element: AuthorizationPage(
        <ApproveWithdrawalDetail />,
        "/phe-duyet-rut-tien"
      ),
    },
    {
      path: "/403",
      element: <Page403 />,
    },
    {
      path: "/404",
      element: <Page404 />,
    },
    {
      path: "/500",
      element: <Page500 />,
    },
  ]);
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  );
}
export default MainRouter;
