import {
  TeamOutlined,
  BookOutlined,
  FileTextOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

function changeDomain(url) {
  let currentDomain = window.location.hostname;

  if (currentDomain === "localhost") {
    return url;
  }
  currentDomain = currentDomain.split(".").slice(-2).join(".");

  const urlObject = new URL(url);
  const originalDomain = urlObject.hostname.split(".").slice(-2).join(".");
  const newHostname = urlObject.hostname.replace(originalDomain, currentDomain);
  urlObject.hostname = newHostname;
  return urlObject.toString();
}
const customerDomain = changeDomain(process.env.REACT_APP_CUSTOMER_DOMAIN);
const currentERP = changeDomain(process.env.REACT_APP_CURRENT_ERP);
const checkinDomain = changeDomain(process.env.REACT_APP_CHECKIN_DOMAIN);
const webStaffDomain = changeDomain(process.env.REACT_APP_WEB_STAFF);

export const menus = [
  {
    isParent: true,
    key: "khach-hang",
    path: "",
    title: "Khách hàng",
    icon: <TeamOutlined />,
    nested: [
      {
        key: `/khach-hang/thong-tin-chung.html`,
        path: `${customerDomain}khach-hang/thong-tin-chung.html`,
        title: "Tìm kiếm",
      },
    ],
  },

  {
    isParent: true,
    key: "booking",
    path: "",
    title: "Booking",
    icon: <BookOutlined />,
    nested: [
      {
        key: "/admin/listing-booking-test.html",
        path: `${currentERP}admin/listing-booking-test.html`,
        title: "Danh sách",
      },
    ],
  },
  {
    isParent: true,
    key: "/phe-duyet-rut-tien",
    path: "/phe-duyet-rut-tien",
    title: "Phê duyệt rút tiền",
    icon: <BookOutlined />,
    nested: [],
  },
  {
    isParent: true,
    key: "hoa-don",
    path: "",
    title: "Hoá đơn",
    icon: <LineChartOutlined />,
    nested: [
      {
        key: "/",
        path: "/",
        title: "Danh sách bill",
      },
      {
        key: "/danh-sach-hoa-don",
        path: "/danh-sach-hoa-don",
        title: "Bill đã thanh toán",
      },

      {
        key: "/danh-sach/hang-doi-tra.html",
        path: `${currentERP}danh-sach/hang-doi-tra.html`,
        title: "Danh sách hàng trả lại",
      },
      {
        key: "time-line",
        path: checkinDomain,
        title: "Timeline",
      },
      {
        key: "/tra-hang",
        path: "/tra-hang",
        title: "Trả hàng",
      },
    ],
  },
  {
    isParent: true,
    key: "bao-cao",
    path: "",
    title: "Báo cáo",
    icon: <FileTextOutlined />,
    nested: [
      {
        key: "/bi-report/doanh-thu-my-pham",
        path: `${webStaffDomain}bi-report/doanh-thu-my-pham`,
        title: "Mỹ phẩm",
      },
      {
        key: "/bi-report/doanh-thu-dich-vu",
        path: `${webStaffDomain}bi-report/doanh-thu-dich-vu`,
        title: "Dịch vụ",
      },
    ],
  },
];
